<!--提取资金-->
<template>
  <div class="extractcapital">
    <el-form :model="listQuery" ref="listQuery" label-width="auto" style="text-align: left" :rules="rules">
      <!--      奖金钱包-->
      <el-form-item :label="$t('bonus.wallet_es_ES')">
        {{listQuery.FB}} W$
      </el-form-item>
      <!--     金额 -->
      <el-form-item :label="$t('Account.money')" prop="money">
<!--          <el-input style="width: 50%" v-model="listQuery.money"  clearable :placeholder="$t('')" />-->
<!--			<span style="color: #55a532">&nbsp;{{ $t('minimum.withdrawal.amount') }}</span>-->
        <el-row>
          <el-col :span="8">
            <el-input v-model="listQuery.money" @input="inputnum"  />
          </el-col>
          <el-col :span="2">
            <span style="margin-left: 10px;font-size: 18px">W$</span>
          </el-col>
          <el-col :span="8">
            <el-input disabled v-model="this.rate" />
          </el-col>
          <el-col :span="2">
            <span style="margin-left: 10px;font-size: 18px">MXN</span>
          </el-col>
        </el-row>
      </el-form-item>
      <!--      账户信息-->
      <el-form-item :label="$t('Account.information')"  prop="accountCode">
        <el-input maxlength="18" show-word-limit style="width: 50%" v-model="listQuery.accountCode" @input="handleaccountCodeInput"  clearable :placeholder="$t('')" />
      </el-form-item>
      <!--      收款人-->
      <el-form-item :label="$t('poMemberOrder.costUser')"  prop="accountName">
        <el-input style="width: 50%" v-model="listQuery.accountName"  clearable :placeholder="$t('')" />
      </el-form-item>
      <!--      开户银行-->
      <el-form-item :label="$t('bank.of.deposit')"  prop="accountBank">
        <el-input style="width: 50%" v-model="listQuery.accountBank"  clearable :placeholder="$t('')" />
      </el-form-item>
      <!--      手机号-->
      <el-form-item :label="$t('cell-phone.number')"  prop="mobile">
        <el-input style="width: 50%" v-model="listQuery.mobile"  clearable :placeholder="$t('')" />
      </el-form-item>
      <!--   提现方式   -->
      <el-form-item :label="$t('fiBonusAppl.applStyle')" prop="withdrawMethod">
        <el-select v-model="listQuery.withdrawMethod" placeholder="" @change="getwithdrawMethod" style="width: 50%">
          <el-option value=""/>
          <!--       钱包     -->
          <el-option :label="$t('ISR')" :value="1" />
          <el-option :label="$t('FACTURA')" :value="2" />
        </el-select>
      </el-form-item>
      <!--      税号信息-->
      <el-form-item :label="$t('Tax.ID.information')"  required>
        <el-input :maxlength="withdrawMethodlength" @blur="handleactaxIdInput"  style="width: 50%" v-model.trim="listQuery.taxId"  clearable :placeholder="$t('')" />
      </el-form-item>
      <!--      注意-->
      <el-form-item :label="$t('Notice')" >
        <el-input v-model="listQuery.remark" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" clearable :placeholder="$t('')" />
      </el-form-item>
      <el-form-item :label="$t('Total_withdrawal_amount')">
        {{this.amount}} W$
      </el-form-item>
      <!--      交易密码-->
      <el-form-item :label="$t('transaction.password')" prop="password">
        <el-input :placeholder="$t('login.pass.input')" v-model="listQuery.password" show-password></el-input>
      </el-form-item>
    </el-form>
    <span style="float: left">
      <el-button size="mini" type="warning" :loading="btnloading" @click="confirmBtn('listQuery')" plain>{{$t('btn.implement')}}</el-button>
    </span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getPersonalData2, saveOrUpdateFiAcAppl, loadFiAcAppl, lastFiAcAppl, getExRateAndY} from "@/api/rus/api";

export default {
  name: "ExtractCapital",
  data(){
    return{
      symbol:null,
      wallet:null,
      electronics:null,
      listQuery:{
        F$:0,
        B$:0,
        FB:0,
        remark:'',
        taxId:'',
        accountName:'',
        accountBank:'',
        accountCode:'',
        withdrawMethod:'',
        mobile:'',
        money:0
      },
      withdrawMethodlength:100,
      amount:0,
      btnloading:false,
      rules: {
        password: [
          { required: true, message:this.$t('login.pass.input'), trigger: ['change','blur'] }
        ],
        accountName: [
          { required: true, message:this.$t('po.setInfo'), trigger: ['change','blur'] }
        ],
        accountBank: [
          { required: true, message:this.$t('po.setInfo'), trigger: ['change','blur'] }
        ],
        mobile: [
          { required: true, message:this.$t('po.setInfo'), trigger: ['change','blur'] }
        ],
        accountCode: [
          { required: true, message:this.$t('po.setInfo'), trigger: ['change','blur'] }
        ],
        money: [
          { required: true, message: this.$t('label.pleaseFillIn'), trigger: ['change','blur'] }
        ],
        acType: [
          { required: true, message: this.$t('me.choosetype'), trigger: ['change','blur'] }
        ],
        withdrawMethod: [
          { required: true, message: this.$t('mi.input.select'), trigger: ['change','click'] }
        ],
      }
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'userCode',
      'username',
      'ckCode',
      'companyCode',
    ]),
    rate(){
      if(this.listQuery.money){
        return (this.listQuery.money * this.FBrate).toFixed(2)
      }
      return 0
    }
  },
  created() {
    this.getBalance()
    this.getSymbol()
    this.getInformation()
    this.acTypeChange()
  },
  methods:{
    getwithdrawMethod(){
      // this.listQuery.taxId = ''
      // if(this.listQuery.withdrawMethod === 1){
      //   this.withdrawMethodlength = '13'
      // }else if(this.listQuery.withdrawMethod === 2){
      //   this.withdrawMethodlength = '12'
      // }
    },
    handleaccountCodeInput(value) {
      // 只允许输入数字
      this.listQuery.accountCode = value.replace(/[^\d]/g, '');
    },
    handleactaxIdInput() {
      console.log(111)
      // if(this.listQuery.withdrawMethod===1){
      //   if (!/^[A-Za-z]{4}\d{6}[A-Za-z0-9]{3}$/.test(this.listQuery.taxId)) {
      //     this.listQuery.taxId = ''
      //   }
      // }else if(this.listQuery.withdrawMethod===2){
      //   if (!/^[A-Za-z]{3}\d{6}[A-Za-z0-9]{3}$/.test(this.listQuery.taxId)) {
      //     this.listQuery.taxId = ''
      //   }
      // }
    },
    inputnum(){
      this.listQuery.money = this.listQuery.money.replace(/^(-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
    },
    acTypeChange () {
      this.listQuery.money = 0
      getExRateAndY({ 'acType': 'FB' }).then(res => {
        this.yue = res.data.yue
        this.FBrate = res.data.rate
      })
    },
    getInformation(){
      lastFiAcAppl().then(res=>{
        console.log(res,'获取上次填写信息')
        if(res.data){
          this.listQuery.money = ''
          this.listQuery.remark = res.data.memo
          this.listQuery.taxId = res.data.taxId
          this.listQuery.accountName = res.data.accountName
          this.listQuery.accountBank = res.data.accountBank
          this.listQuery.accountCode = res.data.accountCode
          this.listQuery.mobile = res.data.mobile
          this.listQuery.withdrawMethod = res.data.withdrawMethod
          // if(this.listQuery.withdrawMethod === 1){
          //   this.withdrawMethodlength = '13'
          // }else if(this.listQuery.withdrawMethod === 2){
          //   this.withdrawMethodlength = '12'
          // }
        }else{
          this.listQuery.money = 0
          this.listQuery.remark = ''
          this.listQuery.taxId = ''
          this.listQuery.accountName = ''
          this.listQuery.accountBank = ''
          this.listQuery.accountCode = ''
          this.listQuery.mobile = ''
          this.listQuery.withdrawMethod = ''
        }
      })
    },
      getSymbol() {
          if (this.companyCode === 'RUS') {
              //单位(货币)
              this.symbol = 'Руб'
              this.electronics = '$'
              //符号
              this.wallet = '₽'
          } else if (this.companyCode === 'MNG') {
              this.symbol = 'MNG'
              this.wallet = '₮'
          } else if (this.companyCode === 'KAZ') {
              this.symbol = 'Тг'
              this.wallet = '₸'
          } else if (this.companyCode === 'KGZ') {
              this.symbol = 'Сом'
              this.wallet = 'лв'
          } else if (this.companyCode === 'TJK') {
              this.symbol = 'somoni'
              this.wallet = 'SM'
          }
      },
    getBalance(){
      getPersonalData2({'userCode':this.userCode}).then(res=>{
            for(let i=0;i<res.data.length;i++){
              if(res.data[i].acType === 'B$'){
                this.listQuery.B$ = res.data[i].validBalance
              }
              if(res.data[i].acType === 'FB'){
                this.listQuery.FB = res.data[i].validBalance
              }
              if(res.data[i].acType === 'F$'){
                this.listQuery.F$ = res.data[i].validBalance
              }
            }
      })
      loadFiAcAppl().then(res=>{
        this.amount = res.data.applLimit
      })
    },
    confirmBtn(listQuery){
        // if(this.listQuery.money < 10) {
        //   return this.$message.error(this.$t('Minimum.reflected.amount'))
        // }
      if(this.listQuery.accountCode.toString().length != 18){
        return this.$message.error(this.$t('Account.information.must.be.18 pure.digits'))
      }
      this.$refs[listQuery].validate((valid) => {
        if (valid) {
          this.$confirm(this.$t('fi.sure.handle'), this.$t('common.tips'), {
            confirmButtonText: this.$t('button.confirm'),
            cancelButtonText: this.$t('operation.button.cancel'),
            customClass: 'msgBox',
            type: 'warning'
          }).then( () => {
          this.btnloading = true
          saveOrUpdateFiAcAppl({...this.listQuery}).then(()=>{
            this.btnloading = false
            this.$message.success(this.$t('bdSendRegister.operaterSuccess'))
            this.$router.push({name:'DrawingHistory'})
          }).catch(err=>{
            this.btnloading = false
            this.$message.error(this.$t('bdSendRegister.operaterFail'))
            console.error(err)
          })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('operation.button.cancel')
            })
          })
        } else {
          return false
        }
      })
    },
    resetForm(listQuery) {
      this.$refs[listQuery].resetFields()
    }
  }
}
</script>

<style scoped>

</style>
